import { IconClose } from 'ui/components/IconClose';
import IconWrap from './../ConfirmationPopup/styled/IconWrap';
import { Text } from '../AdvancedSettingsPopup/styled';
import ButtonsWrap from '../ConfirmationPopup/styled/ButtonsWrap';
import { AddGroup } from 'routes/pages/GroupsPage/styled';
import { AddUserButton } from '../UserPopup/styled';
import { useEffect, useState } from 'react';
import { ContentWrap, ExtensiveSettingsWrap } from './styled';
import groupsStore from 'store/groupsStore';
import { observer } from 'mobx-react';

const ExtensiveSettings = observer(({ id, text, onClose, closeModal, group, setIsSettingsModalVisible }) => {
    const [localOptions, setLocalOptions] = useState(() => ({ ...group }));

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setLocalOptions((prev) => ({
            ...prev,
            [id]: checked,
        }));
    };

    const handleSave = async () => {
        await groupsStore.updateGroup(id, localOptions);
        setIsSettingsModalVisible(false);
        closeModal();
    };

    return (
        <ExtensiveSettingsWrap>
            <IconWrap>
                <p>{text}</p>
                <button onClick={onClose}>
                    <IconClose />
                </button>
            </IconWrap>
            <ContentWrap>
                <Text $color="#163C85">Доступ пользователей к курсам</Text>
                <input
                    type="checkbox"
                    id="isAccess"
                    checked={localOptions.isAccess}
                    onChange={handleCheckboxChange}
                />
            </ContentWrap>
            <ButtonsWrap>
                <AddGroup onClick={onClose}>Отменить</AddGroup>
                <AddUserButton onClick={handleSave}>Сохранить</AddUserButton>
            </ButtonsWrap>
        </ExtensiveSettingsWrap>
    )
});

export default ExtensiveSettings;

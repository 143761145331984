import styled from "styled-components";

var buttoncolor = '#8fabf85a';

const PaginationWrap = styled.div`
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    & div {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
    }

    & button {
        border-radius: 5px;
        background-color: ${buttoncolor};
        padding: 8px 12px;
        display: flex;
        align-items: center;
        border: 1px solid transparent
    }

    & button.active  {
        border: 1px solid #2150d1;
        border-radius:5px;
        background-color: #8fabf87f;

    }
`;

export default PaginationWrap
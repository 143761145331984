import React, { useEffect, useState } from 'react';
import { Card, Form, NotElements } from ".";
import { CoursesListBlock, CoursesListWrap, PaginationWrap } from "../styled";
import { Pagination } from 'ui/components/Pagination';
import groupsStore from 'store/groupsStore';
import { observer } from 'mobx-react';
import coursesStore from "../../../../store/coursesStore";

const CoursesList = observer(({ group }) => {
    const courses = groupsStore.getCoursesForGroup(group.id);
    const { currentPage, totalPages } = groupsStore.getCoursePaginationForGroup(group.id);

    useEffect(() => {
        groupsStore.loadCoursesForGroup(group.id, currentPage);
    }, [group.id, currentPage]);


    const handlePageChange = (newPage) => {
        groupsStore.setCoursePageForGroup(group.id, newPage);
    };

    const hasOtherPages = totalPages > 1;
    const isEmptyPage = courses.length === 0 && hasOtherPages;

    return (
        <CoursesListBlock>
            <Form type={"courses"} text="Добавить курс" title="Курсы" />

            <CoursesListWrap>
                {
                    courses.length !== 0
                        ? courses.map((courseGroup) => (
                            <Card
                                key={courseGroup.courseId}
                                item={courseGroup}
                                type="course"
                                group={group}
                            />
                        ))
                        : <NotElements text={"На данный момент в группе нет курсов"} />
                }
            </CoursesListWrap>
            {
                courses.length !== 0 && (
                    <PaginationWrap>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setIndex={handlePageChange}
                        />
                    </PaginationWrap>
                )
            }
        </CoursesListBlock >
    );
});

export default CoursesList;

import styled from "styled-components"

const AddGroup = styled.button`
    color:#163C85;
    cursor:pointer;
    flex:50%;
    max-width:300px;
    overflow-y:hidden;
    white-space:nowrap;

    scrollbar-width: thin;
    scrollbar-color: #58aee7 #f0f0f0;
`

export default AddGroup 
// store/groupsStore.js
import { api } from "api";
import { makeAutoObservable } from "mobx";
import { addNewGroup, deleteGroup, loadCoursesForGroup, loadGroups } from "routes/pages/GroupsPage/api";
import { getCourseNotGroup } from "routes/pages/ModerPage/api";
import { usersNotGroup } from "routes/pages/UsersPage/api";
import { addNewCourse, addNewUser, copyLink, coursesNotGroupFilters, deleteCourse, deleteLink, deleteUser, updateGroup } from './../routes/pages/GroupsPage/api/actions';

class Groups {
    pageNumber = 1;
    pageSize = 10;
    groups = [];
    totalPages;
    users = [];
    courses = [];
    currentPageCourse;
    totalPagesCourse;
    totalCourses;
    currentPageUser;
    totalPagesUser;
    totalUsers;
    link = '';
    options = { isaccess: false, visible: false, name: '', numberOfParticipants: 0 };
    selectedGroup = null;
    groupPagination = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    async loadGroups(pageNumber, pageSize) {
        const response = await loadGroups(pageNumber, pageSize);
        if (response !== undefined) {
            this.setGroups(response.groups);
            this.totalPages = response.totalPages;
            this.pageNumber = response.currentPage;
            // Инициализируем пагинацию для каждой группы
            response.groups.forEach(group => {
                if (!this.groupPagination.has(group.id)) {
                    this.groupPagination.set(group.id, {
                        courses: { currentPage: group.coursePageInfo.currentPage, totalPages: group.coursePageInfo.totalPages, data: group.courses },
                        users: { currentPage: group.userPageInfo.currentPage, totalPages: group.userPageInfo.totalPages, data: group.users },
                    });
                }
            });
            return response;
        }
    }

    async refreshSelectedGroup() {
        if (this.selectedGroup) {
            const groupId = this.selectedGroup.id;
            const response = await loadGroups(1, 10); // Загружаем все группы
            const updatedGroup = response.groups.find(group => group.id === groupId);
            if (updatedGroup) {
                this.setSelectedGroup(updatedGroup);
            } else {
                console.log(`Группа с ID ${groupId} не найдена при обновлении.`);
            }
        } else {
            console.log("Нет выбранной группы для обновления.");
        }
    }

    async addNewGroup(newGroup) {
        const response = await addNewGroup(newGroup);
        if (response.status === 200) {
            this.loadGroups(1, 10);
        }
    }

    async deleteGroup(id) {
        const response = await deleteGroup(id);
        if (response.status === 200) {
            this.loadGroups(1, 10);
        }
    }

    async copyLink(id) {
        const response = await copyLink(id);
        this.setLink(response);
    }

    async deleteLink(groupId) {
        await deleteLink(groupId);
        this.setLink('');
    }

    async addNewCourse(groupId, courseId) {
        const response = await addNewCourse(groupId, courseId);
        if (response.status === 200) {
            await this.loadGroups(this.pageNumber, this.pageSize);
            await this.loadCoursesForGroup(groupId, this.getCoursePaginationForGroup(groupId).currentPage);
        }
    }

    async deleteCourse(groupId, courseId) {
        const response = await deleteCourse(groupId, courseId);
        if (response.status === 200) {
            await this.loadGroups(this.pageNumber, this.pageSize);
            const currentPage = this.getCoursePaginationForGroup(groupId).currentPage;
            await this.loadCoursesForGroup(groupId, this.getCoursePaginationForGroup(groupId).currentPage);

            const updatedCourses = this.getCoursesForGroup(groupId);
            const updatedTotalPages = this.getCoursePaginationForGroup(groupId).totalPages;

            if (updatedCourses.length === 0 && updatedTotalPages > 0 && currentPage > 1) {
                const newPage = updatedTotalPages;
                this.setCoursePageForGroup(groupId, newPage);
                await this.loadCoursesForGroup(groupId, newPage);
            }
        }
    }

    async addNewUser(groupId, userId) {
        const response = await addNewUser(groupId, userId);
        if (response.status === 200) {
            await this.loadGroups(this.pageNumber, this.pageSize);
            await this.loadUsersForGroup(groupId, this.getUserPaginationForGroup(groupId).currentPage);
        }
    }

    async deleteUser(groupId, userId) {
        const response = await deleteUser(groupId, userId);
        if (response.status === 200) {
            await this.loadGroups(this.pageNumber, this.pageSize);
            const currentPage = this.getUserPaginationForGroup(groupId).currentPage;
            await this.loadUsersForGroup(groupId, this.getUserPaginationForGroup(groupId).currentPage);

            const updatedUsers = this.getUsersForGroup(groupId);
            const updatedTotalPages = this.getUserPaginationForGroup(groupId).totalPages;

            if (updatedUsers.length === 0 && updatedTotalPages > 0 && currentPage > 1) {
                // Если страница пустая, но есть другие страницы, переходим на последнюю
                const newPage = updatedTotalPages;
                this.setUserPageForGroup(groupId, newPage);
                await this.loadUsersForGroup(groupId, newPage);
            }
        }
    }

    async loadCoursesForGroup(id, page, pageSize = 10) {
        const response = await loadCoursesForGroup(id, page, pageSize);
        console.log(response)
        if (response) {
            const pagination = this.groupPagination.get(id) || { courses: {}, users: {} };
            pagination.courses = {
                currentPage: page,
                totalPages: response.data.totalPages,
                data: response.data.courses,
            };
            this.groupPagination.set(id, pagination);
            this.currentPageCourse = page;
            return response.data;
        }
    }

    async loadUsersForGroup(groupId, page = 1, pageSize = 10) {
        const response = await api.get('groups/GroupUsers', {
            params: { groupId, pageNumber: page, pageSize }
        });
        if (response) {
            const pagination = this.groupPagination.get(groupId) || { courses: {}, users: {} };
            pagination.users = {
                currentPage: page,
                totalPages: response.data.totalPages,
                data: response.data.users,
            };
            this.groupPagination.set(groupId, pagination);
            this.currentPageUser = page;
        }
    }

    async updateGroup(id, updatedData) {
        await updateGroup(id, updatedData);
        await this.loadGroups(1, 10);
    }

    async loadCourseNotGroup(idgroup, currentPage = 1) {
        const { data } = await getCourseNotGroup(idgroup, currentPage);
        this.setCourses(data.allCourses);
        this.totalCourses = data.totalPages;
    }

    async coursesNotGroupFilters(id, arr_filters) {
        const { data } = await coursesNotGroupFilters(id, arr_filters);
        this.setCourses(data.allCourses);
        this.totalCourses = data.totalPages;
    }

    async loadUsersNotGroup(groupId, currentPage = 1) {
        const response = await usersNotGroup(groupId, currentPage);
        this.setUsers(response.data.user);
        this.totalUsers = response.data.totalPages;
    }

    setGroups(data) {
        this.groups = data;
    }

    setCurrentPageCourse(page) {
        this.currentPageCourse = page;
    }

    setCurrentPageUser(page) {
        this.currentPageUser = page;
    }

    setLink(link) {
        this.link = link;
    }

    setSelectedGroup(group) {
        this.selectedGroup = { ...group };
        if (!this.groupPagination.has(group.id)) {
            this.groupPagination.set(group.id, {
                courses: { currentPage: 1, totalPages: 1, data: [] },
                users: { currentPage: 1, totalPages: 1, data: [] },
            });
        }
    }

    setUsers(data) {
        this.users = data;
    }

    setCourses(data) {
        this.courses = data;
    }

    setCoursePageForGroup(groupId, page) {
        const pagination = this.groupPagination.get(groupId);
        if (pagination) {
            pagination.courses.currentPage = page;
            this.groupPagination.set(groupId, pagination);
        }
    }

    setUserPageForGroup(groupId, page) {
        const pagination = this.groupPagination.get(groupId);
        if (pagination) {
            pagination.users.currentPage = page;
            this.groupPagination.set(groupId, pagination);
        }
    }

    getCoursesForGroup(groupId) {
        return this.groupPagination.get(groupId)?.courses.data || [];
    }

    getUsersForGroup(groupId) {
        return this.groupPagination.get(groupId)?.users.data || [];
    }

    getCoursePaginationForGroup(groupId) {
        return this.groupPagination.get(groupId)?.courses || { currentPage: 1, totalPages: 1 };
    }

    getUserPaginationForGroup(groupId) {
        return this.groupPagination.get(groupId)?.users || { currentPage: 1, totalPages: 1 };
    }
}

export default new Groups();
import React from 'react';
import PaginationWrap from './styled/PaginationWrap';
import { observer } from 'mobx-react';
import { LuChevronRight, LuChevronLeft } from "react-icons/lu";

const Pagination = observer(({ totalPages, currentPage, setIndex }) => {

    const handleClick = (e, page) => {
        e.preventDefault();
        if (page < 1 || page > totalPages) return;
        setIndex(page);
    };

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <PaginationWrap>
            <button className='navigate' onClick={(e) => handleClick(e, currentPage - 1)} disabled={currentPage === 1}>
                <LuChevronLeft style={{ display: 'flex', alignItems: 'center' }} />
                Предыдущий
            </button>
            <div>
                {pageNumbers.map(number => (
                    <button
                        key={number}
                        onClick={(e) => handleClick(e, number)}
                        className={currentPage === number ? 'active' : ''}
                    >
                        {number}
                    </button>
                ))}
            </div>
            <button className='navigate' onClick={(e) => handleClick(e, currentPage + 1)} disabled={currentPage === totalPages}>
                Следующий
                <LuChevronRight style={{ display: 'flex', alignItems: 'center' }} />
            </button>
        </PaginationWrap >
    );
});

export default Pagination;
import { Pagination } from "ui/components/Pagination";
import { Card, Form, NotElements } from ".";
import { CoursesListBlock, CoursesListWrap, PaginationWrap } from "../styled";
import {useEffect, useState} from "react";
import groupsStore from "store/groupsStore";
import { observer } from "mobx-react";

const UsersList = observer(({ group }) => {
    const users = groupsStore.getUsersForGroup(group.id);
    const { currentPage, totalPages } = groupsStore.getUserPaginationForGroup(group.id);

    useEffect(() => {
        groupsStore.loadUsersForGroup(group.id, currentPage);
    }, [group.id, currentPage]);

    const handlePageChange = (newPage) => {
        groupsStore.setUserPageForGroup(group.id, newPage);
    };


    const hasOtherPages = totalPages > 1;
    const isEmptyPage = users.length === 0 && hasOtherPages;

    return (
        <CoursesListBlock>
            <Form type={"users"} text={"Добавить пользователя"} title={"Пользователи"} />

            <CoursesListWrap>
                {
                    users.length !== 0
                        ? users.map((userGroup, index) => (
                            <Card
                                key={`${groupsStore.selectedGroup.id}-${userGroup.user?.id}-${index}`}
                                item={userGroup}
                                type="user"
                                group={group}
                            />
                        ))
                        : <NotElements text={"На данный момент в группе нет пользователей"} />
                }
            </CoursesListWrap>
            {
                users.length !== 0 && (
                    <PaginationWrap>
                        <Pagination
                            currentPage={currentPage}
                            setIndex={handlePageChange}
                            totalPages={totalPages}
                        />
                    </PaginationWrap>
                )
            }
        </CoursesListBlock>
    );
})

export default UsersList;

import styled from "styled-components";

const InputEdit = styled.input`
    border-radius: 6px;
    padding: 10px 150px 10px 16px;     
    min-width:300px;
    border:none;
  overflow: hidden; 
  white-space: nowrap; 
  text-align: left; 
  direction: ltr; 
    &:focus{
            outline: none;
            border-left: 1px solid #58aee7;
            border-right: 1px solid #58aee7;
    }
`

export default InputEdit;